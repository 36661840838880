// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import './add_jquery'

import * as bootstrap from "bootstrap"

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//let popov






document.addEventListener('turbo:submit-end', function () {


    console.log("Submit end was called");

});

document.addEventListener('turbo:before-stream-render', function () {




    console.log("before-stream-render was called");


    // need to find a way to add code to autoscroll in mobile browser...


});